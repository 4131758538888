
@media (max-width: 1024px) and (min-width: 767px) {
    .signup_sec .form-check {
        padding-left: 1em !important;
    }
    .signup_sec .form-check .form-check-input{
        margin-left: -1.2em !important;
    }
}

.not_found {
    padding: 50px;
    min-height: 100vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.not_found img {
    width: 50%;
}

.not_found .not_logo {
    margin-bottom: 15px;
    width: 240px;
}

.not_found button {
    margin-top: 25px;
}

.upload-box {
    align-items: center;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border: 1px dotted #00000075;
    width: 42%;
    margin: auto;
    flex-direction: column;
}

.upload-box i {
    margin-bottom: 20px;
}

.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    height: 60%;
    cursor: pointer;
}

.btn-file {
    background-color: #fff;
    border: 1px solid #ef48b5;
    border-radius: 8px;
    color: #ef48b5;
    font-size: 16px;
    padding: 8px 20px;
}

.thumbs-img {
    margin: 0;
    padding: 0;
}

.thumbs-img li {
    border: 1px dotted #00000075;
    border-radius: 15px;
    border-radius: 10px;
    display: inline-block;
    margin-right: 20px;
    padding: 10px;
}

.Delete-image {
    background: tomato;
    border-radius: 50%;
    cursor: pointer;
    padding: 0 5px;
    position: absolute;
    right: -6px;
    top: -12px;
}

@media screen and (max-width: 1092px) {
    .not_found img {
        width: 100%;
    }

    .not_found .not_logo {
        width: 180px;
    }
}

@media screen and (max-width: 767px) {
    .upload-box {
        width: 100%;

    }
}