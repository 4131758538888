.footer_flex {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 60px 10px;
}

.footer_main {
    background: url(../images/abstractTwo.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.footer_logo {
    width: 33%;
    text-align: left;
    padding: 0px 20px;
    padding-right: 20px;
}

.footer_logo p {
    font-size: 14px;
}

.footer_logo img {
    width: 120px;
    margin-bottom: 15px;
}

.card-logo {
    display: block;
    height: 30px;
    margin: 0 auto;
}

.footer_links {
    width: 33%;
    text-align: left;
    padding-right: 20px;
}

.footer_links ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.footer_links ul li {
    margin-bottom: 5px;
}

.footer_links ul li a {
    color: inherit;
    display: flex;
    width: 100%;
    align-items: center;
}

.footer_links ul li a i {
    color: #ef48b5;
    margin-right: 5px;
}

.footer_contact {
    width: 33%;
    text-align: left;
}

.footer_contact p {
    display: flex;
    align-items: flex-start;
}

.footer_contact p i {
    position: relative;
    top: 5px;
    color: #ef48b5;
    margin-right: 10px;
}

.footer_contact h5 {
    margin-top: 15px;
}



.footer_FSec {
    /* background: url(../images/abstractTwo.jpg); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 40px 10px 0px 10px;
    background: linear-gradient(to right, #300243, #15021b);
}
.footer_FFlex {
    display: flex;
    justify-content: start;
    padding-top: 20px;
}
.logo_footer {
    width: 100%;
    border-top: 1px solid #fff;
    padding-top: 20px;
}
.logo_footer img {
    width: 160px;

}
.footer_FFlex ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.footer_FFlex ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2px;
}

.footer_FFlex ul li a {
    text-decoration: none;
    color: #fff;
}

.footer_FFlex ul li a i {
    color: #ef48b5;
    margin-right: 7px;
}

.footer_FL {
    width: 33%;
    padding-right: 5%;
}

.footer_FM {
    width: 33%;
    padding-right: 5%;
}

.footer_FR {
    width: 33%;
}

.footer_FL img {
    width: 160px;
    margin-bottom: 20px;
}

.footer_bottomBar {
    text-align: center;
    border-top: 1px solid #fff;
    margin-top: 40px;
    padding: 15px 0px;
}

.footer_bottomBar p {
    margin: 0;
    color: #fff;
}
.footer_FR h5 {
    color: #fff;
}
.footer_FM h5 {
    color: #fff;
}
@media screen and (max-width: 662px) {
    .footer_flex {
        flex-wrap: wrap;
        padding: 40px 10px;
    }

    .footer_logo {
        width: 100%;
        margin-bottom: 25px;
        padding: 0px;
    }

    .footer_links {
        width: 100%;
        margin-bottom: 25px;
    }

    .footer_contact {
        width: 100%;
        text-align: left;
    }

    .footer_FSec {
        background-position: center;
        padding: 60px 10px 0px 10px;
    }

    .footer_FFlex {
        flex-direction: column;
    }

    .footer_FFlex ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .footer_FFlex ul li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2px;
    }

    .footer_FFlex ul li a {
        text-decoration: none;
        color: #fff;
    }

    .footer_FFlex ul li a i {
        color: #ef48b5;
        margin-right: 7px;
    }

    .footer_FL {
        width: 100%;
        padding-right: 0%;
        margin-bottom: 20px;
    }

    .footer_FM {
        width: 100%;
        padding-right: 0%;
        margin-bottom: 20px;
    }

    .footer_FR {
        width: 100%;
    }

    .footer_FL img {
        width: 160px;
        margin-bottom: 20px;
    }

    .footer_bottomBar {
        text-align: center;
        border-top: 1px solid #000;
        margin-top: 40px;
        padding: 15px 0px;
    }

    .footer_bottomBar p {
        margin: 0;
    }
}