
.chat_outerName p {
    color: #ef48b5;
    font-size: 16px;
}
img.notiEye {
    position: absolute;
    width: 20px !important;
    height: 20px !important;
    right: 10px !important;
    background: #dfdfdf !important;
    border-radius: 50px !important;
    padding: 3px !important;
}
button.read_button {
    background-color: #ccc3c3;
    color: #000;
    font-size: 16px;
    border: none;
    outline: none;
    padding: 10px 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.chat_last_message p {
    margin: 0px;
    font-size: 15px;
}
button.unread_button {
    background-color: #ef48b5;
    color: #fff;
    font-size: 16px;
    border: none;
    outline: none;
    padding: 10px 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ntificationone {
    width: 20%;
    position: relative;
}
.show_edit_bgarea_message{
    width: 95%;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 40px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.show_edit_bgarea_message-new{
    width: 80%;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 40px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
.hide_meY{
    display: none;
}
.payment_bg_notification {
    max-height: 35vh;
    overflow-y: scroll;
}

.notification_heading {
    padding: 15px 20px;
    background: #fff;
}

.notification_heading h2 {
    font-size: 20px;
    margin: 0px;
}

.ntificationone img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.notification_text {
    width: 80%;
}

/* scroll bar */
.no-scroll {
    overflow-y: auto !important;
}

.no-scroll::-webkit-scrollbar {
    width: 0px;
}

.payment_bg_notification::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.payment_bg_notification::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
.payment_bg_notification::-webkit-scrollbar-thumb {
    background: #EF48B5;
    border-radius: 10px;
}

/* Handle on hover */
.payment_bg_notification::-webkit-scrollbar-thumb:hover {
    background: #EF48B5;
}

/* scroll bar */

.mainnotification_text {
    display: flex;
    justify-content: space-between;
    padding: 15px 15px;
    background: #F6F6F6;
    cursor: pointer;
}

.notification_text p {
    font-size: 16px;
    margin: 0px;
    font-weight: 600;
    color: #000;
}

.notification_text h2 {
    color: #CBCBCB;
    font-size: 14px;
    margin: 0px;
}

.chat_sec {
    min-height: 60vh;
}

span.bonus {
    font-size: 12px;
    font-weight: 600;
    color: #0D860D;
}

.chat_flex {
    display: flex;
    justify-content: center;
}

.chat_flexL {
    width: 100%;
    background: #fff;
    min-height: 100vh;
    overflow-y: scroll;
    height: 80vh;
}

.chat_topFlex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    background: linear-gradient(90deg, #ef48b5, #F209E1);
}

.chat_topL {
    width: 20%;
}

.chat_topL button {
    background: #fff;
    border: none;
    border-radius: 50%;
    padding: 10px 15px;
}

.chat_topR {
    width: 80%;
}

.searchBar {
    /* background: #8D6DCB;
    border-bottom: 2px solid #f7f7f7;
    padding: 20px; */
    position: relative;
}

.searchBar input {
    position: relative;
    padding: 10px 20px !important;
    border-radius: 50px;
    font-size: 14px;
}

.searchBar i {
    position: absolute;
    right: 6%;
    bottom: 28%;
}

.chat_flexL::-webkit-scrollbar {
    width: 4px;
}

.chat_flexL::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #8D6DCB;
    border-radius: 10px;
}

.chat_flexL::-webkit-scrollbar-thumb {
    background: #02224e;
    border-radius: 10px;
}

.chat_flexL::-webkit-scrollbar-thumb:hover {
    background: #02224e;
}

.chat_flexR {
    width: 100%;
    position: relative;
}

.chat_loader {
    height: 100%;
    width: 100%;
    position: absolute;
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(3px);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat_loader .spinner-border {
    height: 3rem;
    width: 3rem;
}

.chat_outer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #F4F0FD;
    border-bottom: 2px solid #dfdfdf;
    padding: 20px 25px;
    position: relative;
    cursor: pointer;
}

.chat_outerImg {
    width: 10%;
    margin-right: 15px;
}

.chat_outer img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    box-shadow: 3px 5px 10px -5px rgba(0, 0, 0, 0.8);
    object-fit: cover;
}

.chat_outerName {
    width: 30%;
}
.chat_last_message {
    width: 43%;
}
.read_btn {
    width: 20%;
    display: flex;
    justify-content: end;
}
.chat_outerName span {
    display: inline-block;
    position: absolute;
    right: 20px;
    bottom: 10px;
    font-size: 14px;
}

.chat_outerName span i {
    margin-right: 3px;
}

.chat_outerName h5 {
    margin: 0;
    color: #02224e;
    font-size: 18px;
    text-transform: capitalize;
}

.chat_outerName p {
    margin: 0;
    font-size: 13px;
}

.chat_expHead {
    background: #d6ddea;
    display: flex;
    align-items: center;
    padding: 20px 30px;
    justify-content: flex-start;
    border-bottom: 2px solid #dfdfdf;
}

.chat_expHeadL img {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    box-shadow: 3px 5px 10px -5px rgba(0, 0, 0, 0.8);
    margin-right: 10px;
    object-fit: cover;
}

.chat_expHeadR h5 {
    margin: 0;
    color: #02224e;
    font-size: 18px;
    text-transform: capitalize;
}

.chat_expHeadR h5 i {
    color: rgb(3, 104, 3);
    margin-left: 12px;
    position: relative;
    top: 2px;
}

/* .chat_expBody {
    padding: 20px 50px;
    max-height: 90vh;
    min-height: 90vh;
    overflow-y: scroll;
    background-image: url(../images/abstractTwo.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
} */
.chat_expBody {
    padding: 20px 50px;
    overflow-y: scroll;
    background-image: url(../images/abstractTwo.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 38vh;
}

.chat_expBody::-webkit-scrollbar {
    width: 7px;
}

.chat_error {
    text-align: center;
    font-size: 22px;
}

.chat_error i {
    margin-right: 7px;
}

.chat_expBody::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

.chat_expBody::-webkit-scrollbar-thumb {
    background: linear-gradient(90deg, #300243, #15021b);
    border-radius: 10px;
}

.chat_expBody::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(90deg, #300243, #15021b);
}

.chat_right .text_message {
    background: linear-gradient(90deg, #ef48b5, #F209E1);
}
.chat_expBodySmall {
    padding: 20px 50px;
    overflow-y: scroll;
    background-image: url(../images/abstractTwo.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 180px;
}

.chat_expBodySmall::-webkit-scrollbar {
    width: 7px;
}



.chat_expBodySmall::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

.chat_expBodySmall::-webkit-scrollbar-thumb {
    background: linear-gradient(90deg, #300243, #15021b);
    border-radius: 10px;
}

.chat_expBodySmall::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(90deg, #300243, #15021b);
}


.text_message {
    display: inline-block;
    background: linear-gradient(90deg, #300243, #15021b);
    color: #fff;
    margin: 0;
    padding: 5px 15px 5px 15px;
    border-radius: 50px;
    margin-bottom: 25px;
    position: relative;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1px;
    text-align: left;
}

.text_message i {
    font-size: 10px;
    margin-left: 3px;
}



.text_message~span i {
    margin-right: 2px;
}

.chat_footer {
    background: #d6ddea;
    padding: 30px 50px;
}

.chat_footer_flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.chat_footer form .chat_footer_flex input {
    padding: 12px !important;
    width: 100%;
    flex: 1;
    margin-right: 15px;
    margin-bottom: 0px;
}

.chat_footer form .chat_footer_flex button {
    padding: 13px 15px;
    font-size: 14px;
}

.chat_footer form .chat_footer_flex button i {
    color: #fff;
    margin: 0;
}

.chat_footer form .chat_footer_flex button:hover>i {
    color: #02224e;
}

.chat_footer_flex input:disabled {
    cursor: no-drop;
}

.chat_footer_flex button:disabled {
    cursor: no-drop;
}

.chat_left {
    text-align: left;
    position: relative;
}

.chat_right {
    text-align: right;
    position: relative;
}

.chat_right span {
    position: absolute;
    right: 5px;
    bottom: 7px;
    font-size: 11px;
}

.chat_left span {
    position: absolute;
    left: 5px;
    bottom: 7px;
    font-size: 11px;
}



.profile_notification {
    width: 100% !important;
    right: 88% !important;
}

.notification_main {
    width: 30%;
    position: absolute;
    right: 23%;
    background: #fff;
    z-index: 9999;
    top: 50px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}


.payments_popup {
    position: fixed;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.payments_flexOne {
    display: flex;
    justify-content: center;
    position: relative;
}

.payments_flexOne .payments_plan {
    width: 100%;
    margin: 1%;
    text-align: center;
    padding: 60px 20px;
    background: #dfdfdf;
    border-radius: 10px;
}

.payments_inner {
    max-width: 85%;
    width: 100%;
    margin: auto;
    padding: 40px 20px;
    border-radius: 10px;
}

.payment_bg {
    display: flex;
    justify-content: center;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    position: relative;
}

@media screen and (max-width: 767px) {
    .chat_expBody {
        min-height: 45vh !important;
    }
    .notification_main {
        width: 84%;
        right: 8%;

    }
    .chat_last_message {
        display: none;
    }
    .show_edit_bgarea_message-new{
        width: 100%;
    }
    .text_message {
        border-radius: 10px;
    }
    .show_edit_bgarea_message-new{
        margin-top: 0px !important;
    }
}
@media screen and (max-width: 1050px){
    .chat_expBody {
        padding: 20px;
        min-height: 45vh;
        max-height: 350px;
        height: 30vh;
    }
}
@media screen and (max-width: 950px) {
    .chat_outerImg {
        margin-right: 5px;
    }

    .payments_flexOne {
        overflow-x: scroll;
    }

    .payments_flexOne .payments_plan {
        width: 100%;
    }

    .payments_inner {
        max-width: 85%;
        padding: 40px 0px;
    }

  

    .chat_flex {
        flex-direction: column;
    }

    .chat_flexL {
        width: 100%;
    }

    .hide_meY {
        display: none;
    }

    .chat_flexR {
        width: 100%;
        
    }
    .fixme{
        position: fixed;
        left: 0;
        top: 0;
    }
    .foxin{
        position: fixed;
        top: 0;
    }

    .chat_flex {
        position: relative;
    }

    .back_buttonT {
        display: block;
        margin-right: 15px;
    }

    .back_buttonT button {
        border-radius: 50%;
        border: none;
        padding: 8px 13px;
    }

    .chat_expBodySmall {
        padding: 20px;
    }
    .chat_sec {
        min-height: auto;
        margin-top: 10px;
    }
    .chat_flexL {
        min-height: 80vh;
    }

    .chat_expHead {
        padding: 15px 20px;
    }

    .chat_footer {
        padding: 20px;
    }

    .chat_outer {
        padding: 15px;
    }

    .chat_outer img {
        height: 40px;
        width: 40px;
    }
    .chat_footer_flex .main_button{
        padding: 15px !important;
    }

    .chat_outerName h5 {
        font-size: 16px;
        word-break: break-all;
    }

    .chat_outerName span {
        font-size: 12px;
    }

    .chat_expHeadL img {
        height: 40px;
        width: 40px;
    }

    .chat_expHeadR h5 {
        font-size: 16px;
    }

    .searchBar input {
        font-size: 12px;
    }

    .searchBar i {
        bottom: 30%;
    }
}

@media screen and (min-width: 767px)and (max-width: 1024px) {
    .show_edit_bgarea_message-new{
        width: 100%;
    }
    .text_message {
        border-radius: 20px;
    }
}