.single_pL_main {
    width: 30%;
}
.single_pR_main {
    width: 25%;
    display: none;
}
h3.text_about_me {
    font-size: 16px;
    margin: 0px;
    padding: 10px 0px;
}
.about_me_info {
    padding: 10px 12px;
}
.about_me_info h2 {
    font-size: 24px;
}
.single_profileArea {
    padding: 60px 15px;
    background: url(../images/abstractTwo.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
button.send_m a {
    color: #fff;
}
.single_Pflex {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
}
.gallery_innerNew {
    width: 29%;
    margin: 2%;
    position: relative;
    z-index: 2;
}
.gallery_innerNew img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    pointer-events: none;
    object-position: top;
}
.single_Pflex .single_pL {
    width: 100%;
    background: #fff;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0px 0px 3px -5px rgba(0, 0, 0, 0.5);
}

.single_Pflex .single_pM {
    width: 70%;
    margin: 0% 1%;
    background: #fff;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0px 0px 3px -5px rgba(0, 0, 0, 0.5);
}

.single_Pflex .single_pR {
    width: 100%;
    background: #fff;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0px 0px 3px -5px rgba(0, 0, 0, 0.5);
}

.recently_joined h3 {
    font-size: 18px;
    margin-bottom: 15px;
    text-align: center;
}

.recently_joined .active_recent {
    flex-wrap: wrap;
    margin: 0px;
}

.recently_joined .active_rInner {
    width: 46%;
    margin: 2%;
    text-align: center;
}

.recently_joined .active_rInner h4 {
    padding: 2px 10px;
}

.single_gallerySec {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.gallery_inner {
    width: 29%;
    margin: 2%;
    position: relative;
    z-index: 2;
    height: 120px;
}

.gallery_inner img {
    width: 100%;
    height: 120px;
    object-fit: cover;
    pointer-events: none;
    filter: blur(4px);
}

.lock_overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    box-shadow: inset 0 0 0 200px rgba(255, 255, 255, 0.05);
    filter: blur(4px);
    z-index: 99998;
}

.gallery_inner i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999;
    font-size: 28px;
    color: #333333;
}

.send_m {
    width: 98%;
    background: linear-gradient(to right, #ef48b5, #F209E1);
    padding: 10px;
    color: #ef48b5;
    margin: 25px 1% 20px 1%;
    border: none;
    outline: none;
    color: #fff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.send_m i {
    margin-left: 10px;
    font-size: 14px;
}
.Mobile_profile p{
    margin: 0px;
    text-transform: capitalize;
}
@media screen and (max-width: 767px) {
    .send_m {
        width: 70%;
        margin: auto;
    }
    .single_gallerySec {
        justify-content: space-between;
    }
    .Mobile_profile {
        width: 50%;
        display: flex;
        align-items: center;
        padding-top: 14px;
    }
    h3.text_about_me{
        padding: 0px;
    }
    .Mobile_profile p {
        margin: 0px;
        word-break: break-word;
    }
    .gallery_innerNew {
        width: 45%;
    }
    .single_pL_main {
        width: 100%;
    }
    .single_pR_main {
        width: 100%;
    }
    .single_profileArea {
        padding: 20px 10px;
    }

    .single_Pflex {
        flex-direction: column;
        align-items: center;
    }

    .single_pL {
        width: 100% !important;
        margin-bottom: 15px;
    }

    .single_pM {
        width: 100% !important;
        margin-bottom: 15px !important;
    }

    .single_pM .send_m {
        margin: 20px 0px;
    }

    .single_pR {
        width: 100% !important;
    }
    .single_lockOpen img {
        max-width: 185px;
        border-radius: 5px;
        margin-bottom: 15px;
        min-height: 20vh;
        object-fit: cover;
    }
    .single_lockOpen {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

}
@media (min-width: 767px) and (max-width: 1024px){
    .single_Pflex{
        flex-wrap: wrap;
        justify-content: center;
    }
    .single_pL_main{
        width: 33%;
        margin-right: 2%;
    }
    .single_Pflex .single_pM{
        width: 63%;
    }
    .single_pR_main{
        width: 50%;
        margin-top: 20px;
    }
    .single_lockOpen img {
        height: 22vh;
    }

}