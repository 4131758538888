.profile_right button.active,
.profile_left button.active {
    border-bottom: 2px solid #fff;
}

.recent_uploads {
    padding: 6px 20px;
}

.not_friends {
    width: 100%;
    text-align: center;
    padding: 50px 0px;
}

.not_friends h2 {
    padding: 20px 0px;
    font-size: 28px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 55px;
    height: 28px;
}

.edit_profile_area {
    width: 55% !important;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 5px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
}

input:checked+.slider {
    background-color: #ef48b5;
}

input:focus+.slider {
    box-shadow: 0 0 1px #ef48b5;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.enable_notification {
    display: flex;
    justify-content: space-between;
    width: 65%;
    margin: auto;
    align-items: center;
}

.notification_turn h2 {
    text-align: center;
    font-size: 18px;
    padding: 20px 0px;
}

.show_edit_bgareaone {
    background: #fff;
    padding: 16px 15px;
    margin: 10px 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    width: 55%;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 40px;
}

.show_edit_bgareatwo {
    background: #fff;
    padding: 16px 15px;
    margin: 10px 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    width: 95%;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 40px;
}

.payments_old_data {
    width: 80%;
    margin: auto;
}

.payments_old_data h2 {
    text-align: center;
    padding: 20px 0px;
    font-size: 20px;
}

.edit_profile_function {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0px;
}

.edit_profile_function button {
    margin: 0px 15px;
}

.edit_profile_function button.active {
    border-bottom: 3px solid #ef48b5;
}

.main_craedits_two {
    display: flex;
    justify-content: end;
    align-items: center;
    /* max-width: 1270px; */
    /* margin: auto; */
    position: relative;
}

.main_craedits_one img {
    width: 40%;
}

.maincreadits {
    max-width: 1270px;
    display: flex;
    justify-content: space-between;
    margin: auto;
    padding: 20px 10px;
}

.main_craedits_one {
    width: 50%;
}

.white_spancredits {
    background: no-repeat;
}

.cradits_bg {
    background: #300243;
}

.upload_areabg {
    background: #ff72a721;
    padding: 24px 0px;
}

.user_profile_page {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    padding: 15px 0px;
}

.user_profile_page h2 {
    font-size: 20px;
    color: #fff;
}

.user_profile_page img {
    width: 70px;
    height: 70px;
    object-fit: cover;
    margin-right: 15px;
    border-radius: 50%;
}

.profile_navbar {
    background: #300243;
    padding-top: 25px;
    padding-bottom: 25px;
}

.profile_right button.active {
    border-bottom: 2px solid #fff;
}

.profile_left button.active {
    border-bottom: 2px solid #fff;
}

.profile_right button {
    color: #fff;
    padding: 10px 12px;
}

.profile_main {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profile_right {
    width: 65%;
}

.profile_left {
    width: 35%;
    display: flex;
    justify-content: flex-end;
}

.profile_left button {
    color: #fff;
    padding: 10px 12px;
}

.main_proflieSec {
    padding: 30px 15px;
    background: url(../images/abstractTwo.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.profileFlex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.image_uploaded {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    margin-top: 7px;
}

.inner_images {
    width: 30%;
    margin: 1%;
    position: relative;
}

.edit_image {
    position: absolute;
    top: 0;
    background: #fff;
    right: 0;
    width: 36%;
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

button {
    border: none;
    background: none;
}

.image_uploaded img {
    width: 100%;
    height: 18vh;
    object-fit: cover;
    border-radius: 5px;
}

.profileFlexL {
    width: 25%;
    position: relative;
}

.gender_iop {
    background: ghostwhite;
    box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    position: absolute;
    padding: 10px 17px;
    top: 2%;
    left: 3%;
}

.gender_iop i {
    color: #FF72A7;
    font-size: 18px;
}

.profileFlexL img {
    height: 220px;
    width: 220px;
    border-radius: 50%;
    object-fit: cover;
    border-top: 6px solid #8C6DCB;
    border-bottom: 6px solid #8C6DCB;
    border-left: 6px solid #FF72A7;
    border-right: 6px solid #FF72A7;
    position: relative;
    animation: change 4s infinite linear;
}

.profileFlexR {
    width: 75%;
}

.profile_title h2 {
    color: #333333;
}

.profile_title h2 i {
    color: rgb(11, 138, 11);
    font-size: 18px;
    position: relative;
    top: -15px;
}

.profile_title p:first-of-type {
    font-size: 18px;
}

.profile_title p:first-of-type i {
    font-size: 15px;
}

.profile_title p {
    margin-bottom: 5px;
}

.profile_title p i {
    color: #8C6DCB;
}

.profile_descriptionSec {
    background: #fff;
    border-radius: 10px;
    width: 100%;
    margin-top: 40px;
    position: relative;

}

.profile_descriptionSec {
    padding: 30px;
}

.profile_dflex {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.profile_dFlexL {
    width: 30%;
    margin-right: 10%;
    position: relative;
}



.profile_dFlexL::after {
    content: '';
    background: linear-gradient(to bottom, #9D6DD3, #FF72A7);
    width: 3px;
    height: 90%;
    position: absolute;
    top: 5%;
    right: -15%;
}

.profileEntry p {
    margin: 0;
}

.profileEntry p:first-of-type {
    font-weight: 600;
    width: 40%;
    text-align: left;
}

.profileEntry p {
    width: 20%;
    text-align: center;
}

.profileEntry p:last-of-type {
    width: 40%;
    text-align: right;
    word-wrap: break-word;
}

.profileEntry p i {
    color: #8C6DCB;
    margin-right: 5px;
}

.profile_dFlexR {
    width: 40%;
}

.profileEntry {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 15px;
    margin-top: 15px;
}

.verified_badge {
    position: absolute;
    right: 0;
    top: 0;
}

.verified_badge img {
    width: 120px;
}

@keyframes change {
    0% {
        border-top: 6px solid #8C6DCB;
        border-bottom: 6px solid #8C6DCB;
        border-left: 6px solid #FF72A7;
        border-right: 6px solid #FF72A7;
    }

    50% {
        border-top: 6px solid #FF72A7;
        border-bottom: 6px solid #FF72A7;
        border-left: 6px solid #8C6DCB;
        border-right: 6px solid #8C6DCB;
    }

    100% {
        border-top: 6px solid #8C6DCB;
        border-bottom: 6px solid #8C6DCB;
        border-left: 6px solid #FF72A7;
        border-right: 6px solid #FF72A7;
    }
}

.allFriend-column {
    display: flex;
    flex-wrap: wrap;
}

.allFriend-column .active_mainProfile {
    width: 31%;
}

.allFriend-column .active_actionSec {
    flex-wrap: wrap;
}

.allFriend-column .allFriend-btn1 {
    width: 48%;
    margin: 1%;
}

.allFriend-column .allFriend-btn2 {
    width: 48%;
    margin: 1%;
}

.allFriend-column .allFriend-btn3 {
    width: 48%;
    margin: 1%;
}

.allFriend-column .allFriend-btn4 {
    width: 48% !important;
    margin: 1%;
}

.toggle_btn {
    display: none;
}

.mobile_hide_credits {
    display: none;
}

@media screen and (max-width: 767px) {
    .show_edit_bgareaone {
        width: 90%;
    }

    .mobile_hide_credits {
        display: block;
    }

    .mobile_area_credits {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0px;
    }

    .toggle_area {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .logo_mobile_nav {
        width: 50%;
    }

    .logo_mobile_nav img {
        width: 70%;
    }

    .hide_mobile_screen {
        display: none;
    }

    .toggle_btn {
        display: block;
        padding: 16px 0px;
        background-color: #300243;
    }

    .toggle_buttons {
        width: 50%;
        display: flex;
        justify-content: end;
    }

    .toggle_buttons svg {
        fill: #fff;
    }

    .payments_old_data {
        width: 100%;
    }

    .save_btnnotification {
        width: 100%;
    }

    .notification_turn h2 {
        font-size: 16px;
        margin: 0px;
    }

    .enable_notification {
        width: 100%;
    }

    .edit_profile_area {
        width: 100% !important;
    }

    .edit_profile_function {
        flex-direction: column;
    }

    .toggle-button {
        display: block;
    }

    .profile_bannerSec {
        min-height: 40vh !important;
    }

    .main_craedits_one img {
        width: 70%;
    }

    .maincreadits {
        /* padding: 20px 14px; */
        display: none;
    }

    .main_craedits_one {
        width: 49%;
        display: flex;
        align-items: center;
    }

    .profile_main {
        flex-direction: column;
    }

    .profile_right {
        flex-direction: column;
        display: flex;
        justify-content: start;
        align-items: start;
        width: 100%;
    }

    .profile_left {
        width: 100%;
        display: flex;
        justify-content: start;
        flex-direction: column;
        align-items: start;
    }

    .profileEntry p:last-of-type {
        word-wrap: break-word;
    }

    .profile_descriptionSec {
        padding: 10px;
    }

    .inner_images {
        width: 100%;
        margin: 4%;

    }

    .edit_image {
        position: absolute;
        top: 0;
        background: #fff;
        right: 0;
        width: 30%;
        height: 15%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    .profileFlex {
        flex-direction: column;
    }

    .profileFlexL {
        width: 100%;
        position: relative;
        text-align: center;
        margin-bottom: 30px;
    }

    .gender_iop {
        top: 2%;
        left: 28%;
    }


    .profileFlexR {
        width: 100%;
    }

    .profile_title h2 i {
        font-size: 14px;
    }

    .profile_dflex {
        flex-direction: column;
    }

    .profile_dFlexL {
        width: 100%;
        margin-right: 0%;
        position: relative;
    }

    .profile_dFlexL::after {
        display: none;
    }


    .profile_dFlexR {
        width: 100%;
    }

    .verified_badge {
        position: absolute;
        right: -0%;
        top: -10%;
    }

    .verified_badge img {
        width: 70px;
    }

    .allFriend-column .active_mainProfile {
        width: 100%;
    }

    .allFriend-column .allFriend-btn1 {
        width: 48% !important;
        margin: 1% !important;
    }

    .allFriend-column .allFriend-btn2 {
        width: 48% !important;
        margin: 1% !important;
    }

    .allFriend-column .allFriend-btn3 {
        width: 98% !important;
        margin: 1% !important;
    }

    .allFriend-column .allFriend-btn4 {
        width: 48% !important;
        margin: 1%;
    }

    .image_uploaded {
        overflow-x: scroll;
        height: 48vh;
    }
}

@media screen and (min-width: 767px) and (max-width: 1024px) {
    .profile_dFlexL::after{
        display: none;
    }

    .top_bannerInner {
        padding: 340px 20px 20px 20px;
    }

    .notification_main {
        width: 45% !important;
        right: 42% !important;
        top: 67px !important;
    }

    .searchBar input {
        font-size: 12px !important;
    }

    .chat_outerName h5 {
        font-size: 12px !important;
    }


    .main_button {
        font-size: 14px;
    }

    .show_edit_bgareaone {
        width: 90%;
    }

    .profileFlexR {
        width: 60%;
    }

    .profileFlexL {
        width: 40%;
    }

    .verified_badge {
        position: absolute;
        right: 0;
        top: -30%;
        width: 18%;
    }

    .profile_dFlexL {
        width: 100%;
        margin-right: 0%;
        position: relative;
        margin-bottom: -15px;
    }

    .profile_dFlexR {
        width: 100%;
    }

    .profileEntry p:first-of-type {
        width: 40%;
    }

    .profileEntry p {
        width: 5%;
    }

    .profileEntry p:last-of-type {
        width: 55%;
    }

    .Mobile_profile {
        width: 50%;
    }

    .send_m {
        font-size: 12px;
    }

    .new_gender {
        flex-wrap: wrap;
    }

    .content_sec h1 {
        font-size: 38px;
        line-height: 58px;
    }

    .profile_title h2 {
        font-size: 14px;
    }

    .profile_title p:first-of-type {
        font-size: 14px;
    }

    .gallery_innerNew {
        width: 46%;
    }

    .upload-box {
        width: 60%;
    }

    .profile_bannerSec h1 {
        font-size: 45px;
    }

    .credits_box {
        margin: 0px 10px;
    }

    .navbar_flex {
        margin: 0px 5px;
    }

    .search_formSec .search_submit {
        font-size: 14px;
    }

    .gallery_inner {
        width: 46% !important;
    }

    .signup_popup {
        min-width: 70%;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1400px) {
    .edit_image {
        width: 45%;
    }
}