/* For Webkit browsers (Chrome, Safari) */
input[type="range"] {
    --thumb-color: #ef48b5 !important; /* Custom property to hold thumb color */
  }
/* For Webkit browsers (Chrome, Safari) */
input[type="range"] {
    --thumb-color: #ef48b5 !important; /* Custom property to hold thumb color */
  }


  .main_spinner{
    display: flex ;
    justify-content: center ;
    align-items: center;
    width: 100%;
  }
  
  input[type="range"]::-webkit-slider-thumb {
    background-color: var(--thumb-color) !important;
    border: 1px solid var(--thumb-color) !important;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -5px;
  }
  
  input[type="range"]::-webkit-slider-runnable-track {
    background: linear-gradient(to right, #ef48b5, #f209e1);
    height: 8px;
    border-radius: 2px;
  }
  
  /* For Firefox */
  input[type="range"]::-moz-range-thumb {
    background-color: var(--thumb-color) !important;
    border: 1px solid var(--thumb-color) !important;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    cursor: pointer;
  }
  
  input[type="range"]::-moz-range-track {
    background: linear-gradient(to right, #ef48b5, #f209e1);
    height: 8px;
    border-radius: 2px;
  }
  
  /* Optional: Style the track (the bar) */
  input[type="range"] {
    width: 100%;
    margin: 10px 0;
    background-color: transparent;
  }
  input#custom-range {
    accent-color: rgba(92, 88, 88, 0.145);
    box-shadow: 0 0 4px 0 #4f4f4f;
}
  
.user_pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px auto 80px auto;
}
.range-slider.single-thumb {
    margin: 17px 0px;
}
.range-slider .range-slider__range {
    position: absolute;
    z-index: 1;
    transform: translate(0, -50%);
    top: 50%;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #ef48b5, #F209E1) !important;
}
.range-slider .range-slider__thumb {
    position: absolute;
    z-index: 3;
    top: 50%;
    width: 24px;
    height: 24px;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background: linear-gradient(to right, #ef48b5, #F209E1) !important;
}
.user_pagination button {
    background: #fff;
    height: 50px;
    width: 50px;
    text-align: center;
    border-radius: 5px;
    margin: 0px 7px;
    line-height: 45px;
    border: 1px solid #dfdfdf;
    box-shadow: 5px 5px 15px -5px rgba(0, 0, 0, 0.5);
    color: #000;
}

.user_pagination button.activated {
    background-color: #D057A6;
    color: #fff;
}
.top_banner {
    /* background: linear-gradient(#30024346, #15021b69), url(../images/dating_app_banner.jpg);; */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 100vh;
    width: 100%;
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.top_banner_two {
    /* background: linear-gradient(#30024346, #15021b69), url(../images/dating_app_banner.jpg);; */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 100vh;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0px;
}

button.button_page {
    border: 2px solid #ef48b5;
    border-radius: 50px;
    margin: 3px 5px;
    width: 28px;
    height: 28px;
}

button.page-btn-active {
    background: #15021b;
    border-radius: 50px;
    margin: 3px 5px;
    width: 28px;
    height: 28px;
    color: #fff;
}

button.send_ok_flirt {
    background: linear-gradient(to right, #ef48b5, #F209E1);
    border-radius: 4px;
    padding: 5px 10px;
    color: #fff;
    font-size: 13px;
}

button.new_flirt_cross {
    position: absolute;
    top: 4px;
    right: 12px;
    width: 16px;
    height: 16px;
}

.main_sendFlirt {
    background: rgba(0, 0, 0, 0.1);
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sendFlirt {
    background: #fff;
    margin: auto;
    text-align: center;
    padding: 25px;
    border-radius: 20px;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.select_singup select {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.select_singup label {
    text-align: start;
    font-size: 13px;
    padding: 2px 10px;
    font-weight: 500;
}

.save_btnnotification {
    display: flex;
    justify-content: end;
    width: 65%;
    margin: auto;
    align-items: center;
}

button.save_btn {
    background: #ef48b5;
    padding: 5px 12px;
    margin: 20px 0px;
    font-size: 16px;
    color: #fff;
}

.cross_btn {
    position: absolute;
    right: 0px;
    top: -2px;
}

.cross_btn i {
    font-size: 24px;
}

.credits_box {
    display: flex;
    justify-content: end;
    align-items: center;
    max-width: 1270px;
    margin: auto;
    position: relative;
}

.notification_area {
    margin-right: 16px;
    position: relative;
    cursor: pointer;
}

.notification_count {
    position: absolute;
    top: -3px;
    right: -13px;
    margin: 0px;
    background: #300243;
    padding: 4px;
    width: 21px;
    height: 22px;
    display: flex;
    justify-content: center;
    border-radius: 20px;
}

.notification_count h1 {
    font-size: 12px;
    margin: 0px;
    color: #fff;
}

.notification_area i {
    font-size: 30px;
    color: #EF48B5;
}

.span_credits {
    display: flex;
    justify-content: end;
    align-items: center;
    flex-direction: column;
    /* border: 2px solid #EF48B5; */
    margin-right: 5px;
    background: #f8f9fa47;
    padding: 5px 10px;
    margin: 0px 10px;
}

.span_credits h1 {
    margin: 0px;
    font-size: 16px;
    color: #300243;
}

.span_credits p {
    margin: 0px;
    color: #300243;
    display: flex;
    justify-content: start;
    width: 100%;
}

.new_gender {
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
}

.new_genderinner {
    display: flex !important;
    justify-content: space-between !important;
}

.sku_btn {
    display: flex;
    justify-content: end;
    align-items: center;
}

button.Skip_btn {
    color: #ef48b5;
    font-size: 16px;
    font-weight: 600;
}

.new_city input {
    height: 50px;
    min-height: 50px;
    font-size: 14px;
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    padding: 1rem 0.75rem;
}

ul.location_new {
    position: absolute;
    background: #fff;
    top: 56px;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    list-style: none;
    padding: 10px 10px;
    max-height: 250px;
    overflow-y: scroll;
    z-index: 999;
}

.about_flexR ul {
    padding-left: 18px;
}

.location_new li {
    padding: 5px 4px;
    cursor: pointer;
    text-align: start;
}

.location_new li:hover {
    background-color: #dfdfdf;
}

.back_button {
    margin: 1% 1%;
}

.login_popup {
    width: 45%;
    padding: 50px 20px;
}

.back_button button {
    background: #fff;
    border: none;
    color: #F113DA;
    border-radius: 20px;
    padding: 5px 12px;
}

.height_search {
    min-height: 100vh;
}

.multi-range-slider .ruler {
    display: none !important;
}

.range_Age {
    width: 15%;
    margin: 10px 2%;
}

.multi-range-slider {
    border: none !important;
    border-radius: 6px !important;
    padding: 17px 10px !important;
    box-shadow: none !important;
}

.multi-range-slider .bar-inner {
    background: linear-gradient(to right, #ef48b5, #F209E1) !important;
    box-shadow: none !important;
}

.new_city {
    display: flex;
    flex-direction: column;
    position: relative;
}

.new_city select#citySelect {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    height: 50px;
    min-height: 50px;
}

.search_gender_inner {
    width: 30%;
    padding-left: 0px;
}

.search_gender_inner p {
    margin-bottom: 0px;
    margin-right: 10px;
}

.search_m .ReactFlagsSelect-module_flagsSelect__2pfa2 {
    width: 70%;
}

.search_m {
    width: 20%;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.search_m input {
    height: 50px;
    min-height: 50px;
    font-size: 14px;
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    padding: 1rem 0.75rem;
    width: 100%;
}

.search_m select#citySelect {
    padding: 15px 10px;
    width: 100%;
    outline: none;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.search_m label {
    margin-right: 18px;
}

.button_search {
    width: 10%;
}

.search_main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.locations_area {
    display: flex;
    flex-wrap: wrap;
}

.locations_area p {
    background: #ddd;
    margin-right: 10px;
    padding: 0px 10px;
    font-size: 14px;
    margin-bottom: 10px;
    cursor: pointer;
}

.active_mainArea {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.top_bannerInner {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    max-width: 1270px;
    /* margin: auto; */
    padding: 250px 20px 20px 20px;
    width: 100%;
}

.signup_sec {
    width: 30%;
}

.content_sec {
    width: 70%;
    padding: 0px 40px;
    padding-left: 10%;
}

.content_sec button {
    margin: 0;
}

.content_sec h1 {
    color: #fff;
    font-size: 60px;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    line-height: 63px;
}

.content_sec p {
    color: #fff;
    font-size: 18px;
    margin-top: 15px;
}

.main_button {
    background: linear-gradient(to right, #ef48b5, #F209E1);
    ;
    color: #fff;
    font-size: 16px;
    border: none;
    outline: none;
    padding: 15px 45px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    box-shadow: 5px 5px 0px 0px #300243;
}

.main_button:hover {
    box-shadow: -5px -5px 0px 0px #300243;
    transition: 0.5s;
}

.main_buttonTwo {
    /* background: linear-gradient(to right, #300243, #15021b); */
    background-color: #ef48b5;
    color: #fff;
    font-size: 16px;
    border: none;
    outline: none;
    padding: 15px 40px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    box-shadow: 5px 5px 0px 0px #300243;
    /* color: 5px 5px 0px 0px #ef48b5; */
}

/* .main_buttonTwo:hover {
    box-shadow: -5px -5px 0px 0px #ef48b5;
    transition: 0.5s;
} */

.main_button i {
    font-size: 14px;
    margin-left: 7px;
    position: relative;
    top: 1px;
}

.main_buttonTwo i {
    font-size: 14px;
    margin-left: 7px;
    position: relative;
    top: 1px;
}

.heartOne {
    position: absolute;
    width: 60px;
    animation: random 2s linear infinite;
    left: 10%;
    transform: rotate(-20deg);
    bottom: 5%;
}

.heartTwo {
    position: absolute;
    width: 60px;
    animation: random 2s linear infinite;
    right: 10%;
    transform: rotate(20deg);
    top: 15%;
}

.main_navbar {
    position: absolute;
    width: 100%;
    z-index: 999;
}

.navbar_flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1270px;
    margin: auto;
    padding: 15px 5px;
}

.navbarL {
    width: 45%;
}

.navbarL img {
    width: 230px;
}

.navbarR {
    width: 55%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
}

.navbarR button {
    display: inline !important;
    margin: 0 !important;
}

.navbarR button.main_buttonTwo {
    margin-right: 25px !important;
}


.active_profilesSec {
    padding: 60px 15px;
    background: linear-gradient(to right, #300243, #15021b);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.active_secFlex {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
}

.active_secFlex .activeL {
    width: 20%;
    box-shadow: 0px 0px 3px -5px rgba(0, 0, 0, 0.5);
}

.activeL_bg {
    background: #fff;
    padding: 20px;
    border-radius: 15px;
}

.active_secFlex .activeM {
    width: 100%;
    text-align: center;
    margin: 0% 1%;
    background: #fff;
    padding: 20px;
    box-shadow: 0px 0px 3px -5px rgba(0, 0, 0, 0.5);
    border-radius: 15px;
    min-height: 100vh;
}

/* .active_secFlex .activeR {
    width: 30%;
} */

.active_recent {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 25px;
    margin-bottom: 30px;
    padding-bottom: 25px;
    border-bottom: 1px solid #dfdfdf;
}

.active_recent a {
    color: inherit;
    width: 23%;
    margin: 1%;
}

.active_rInner {
    width: 100%;
    margin: 0%;
    background: ghostwhite;
    border-radius: 5px;
    border: 1px solid #dfdfdf;
    cursor: pointer;
}

.active_rInner img {
    width: 100%;
    border-radius: 5px 5px 0px 0px;
}

.active_rInner h4 {
    font-size: 16px;
    margin-top: 7px;
}

.active_mainProfile {
    padding: 15px;
    border: 1px solid #dfdfdf;
    border-radius: 10px;
    margin: 1%;
    background: ghostwhite;
    margin-bottom: 15px;
    position: relative;
    width: 31%;
}

.active_mainProfile .add_friend {
    position: absolute;
    top: 10px;
    right: 10px;
    background: linear-gradient(to right, #ef48b5, #F209E1);
    border-radius: 4px;
    padding: 5px 10px;
    color: #fff;
    font-size: 13px;
}

.active_mainProfile .already_friend {
    position: absolute;
    top: 10px;
    right: 10px;
    background: linear-gradient(to right, #300243, #15021b);
    border-radius: 4px;
    padding: 5px 10px;
    color: #fff;
    font-size: 13px;
    border-color: #300243;
}

.active_mainProfile .add_friend i {
    margin-left: 5px;
    color: #fff;
    font-size: 12px;
}

.active_mainFlex {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.active_mainL {
    width: 95%;
}

.active_mainR {
    width: 95%;
    text-align: left;
    /* padding-left: 9px; */
    padding-top: 10px;
}

.active_mainR h4 {
    font-size: 18px;
    margin-bottom: 5px;
}

.active_mainR span.active_age {
    display: inline-block;
    margin-right: 15px;
    font-weight: 600;
    color: #ef48b5;
}

.active_mainR span i {
    margin-right: 3px;
    color: #ef48b5;
    font-size: 12px;
}

.active_mainR p {
    margin: 0;
    border-top: 1px dashed #dfdfdf;
    padding-top: 5px;
    margin-top: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.active_mainL img {
    width: 100%;
    border-radius: 5px;
    object-fit: cover;
    height: 35vh;
    object-position: top;
}

.active_actionSec {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px dashed #dfdfdf;
}

.active_actionSec button {
    background: linear-gradient(to right, #ef48b5, #F209E1);
    padding: 7px 8px;
    border: none;
    color: #fff;
    font-size: 10px;
    border-radius: 5px;
    outline: none;
    width: 30%;
    margin-right: 3%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.active_actionSec button a {
    display: block;
    width: auto;
    color: inherit;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.active_actionSec button:last-of-type {
    width: 31%;
    margin-right: 0%;
    background: linear-gradient(to right, #300243, #15021b);
}

.active_secFlex .activeL ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.active_secFlex .activeL ul li {
    display: flex;
    align-items: flex-start;
    padding: 5px 0px;
}

.active_secFlex .activeL ul li i {
    margin-right: 7px;
    color: #ef48b5;
    margin-right: 7px;
    margin-top: 5px;
}

.active_secFlex .activeL ul li {
    border-bottom: 1px dashed #dfdfdf;
}

.active_secFlex .activeL h4 {
    margin-top: 15px;
    padding-top: 15px;
    text-align: center;
    margin-bottom: 15px;
    border-bottom: 1px solid #dfdfdf;
    padding-bottom: 15px;
    font-size: 22px;
}

.online_profiles {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.onlineInner a {
    width: 31%;
    margin-right: 2%;
    text-align: center;
    position: relative;
    margin-bottom: 20px;
}

.onlineInner {
    width: 100%;
    margin-right: 0%;
    text-align: center;
    position: relative;
    margin-bottom: 20px;
}

.onlineInner img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 50%;
}

.onlineInner i {
    color: rgb(13, 163, 13);
    font-size: 13px;
    position: absolute;
    right: 5%;
    top: 0;
}

.active_secFlex .activeR {
    width: 25%;
    box-shadow: 0px 0px 3px -5px rgba(0, 0, 0, 0.5);
}

.search_formSec .form-check {
    background: ghostwhite;
    padding: 1px 5px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
}

.search_formSec {
    padding-bottom: 25px;
    margin-bottom: 15px;
    border-bottom: 1px dashed #dfdfdf;
}

.search_formSec .form-check .form-check-input {
    margin-left: 0px;
    margin-right: 7px;
    height: 15px;
    width: 15px;
}

.search_formSec .form-check .form-check-input~label {
    font-size: 14px;
}

.search_formSec .form-check .form-check-input:checked~label {
    color: #ef48b5;
}

.search_formSec h4 {
    text-align: center;
    font-size: 18px;
    margin-bottom: 15px;
}

.search_gender {
    display: flex;
    justify-content: center;
    align-items: center;
}

.search_formSec .search_submit {
    background: linear-gradient(to right, #300243, #15021b);
    padding: 10px 10px;
    width: 100%;
    border: none;
    outline: none;
    border-radius: 5px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.search_formSec .search_submit i {
    font-size: 12px;
    margin-left: 8px;
}

.stats_flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px dashed #dfdfdf;
}

.stats_flex p {
    margin: 0;
}

.statsL {
    width: 50%;
    text-align: left;
}

.statsM {
    text-align: center;
    width: 10%;
}

.statsR {
    text-align: right;
    width: 40%;
}

.statsR p {
    background: rgb(13, 134, 13);
    font-size: 14px;
    padding: 4px 15px;
    border-radius: 5px;
    display: inline-block;
    color: #fff;
}

.member_stats h4 {
    font-size: 18px;
    margin-bottom: 25px;
}

.most_visited {
    padding: 60px 10px;
    text-align: center;
    background: linear-gradient(to right, #300243, #15021b);
}

.most_visitedFlex {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 25px;
}

.most_visitedFlex span {
    background: #dfdfdf;
    color: #000;
    margin-right: 20px;
    padding: 5px 20px;
    border-radius: 50px;
    margin-bottom: 15px;
}

.become_part {
    padding: 60px 0px;
    background: linear-gradient(to right, #300243, #15021b);
}

.become_flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    border-radius: 20px;
}

.becomeL {
    width: 20%;
}

.becomeL img {
    width: 100%;
    border-radius: 20px 0px 0px 20px;
}

.becomeR {
    width: 80%;
    padding-left: 50px;
    text-align: left;
}

.becomeR button {
    margin: 0;
}

.profiles_section {
    margin: 0px 0px;
    padding: 60px 15px;
    background: url(../images/abstractTwo.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.main_title {
    text-align: center;
    font-size: 42px;
    position: relative;
    display: inline;
    margin: auto;
    color: #fff;
}

.main_title::before {
    content: '';
    position: absolute;
    width: 30%;
    height: 5px;
    background: linear-gradient(to right, #ef48b5, #F209E1);
    top: 50%;
    left: -35%;
}

.main_title::after {
    content: '';
    position: absolute;
    width: 30%;
    height: 5px;
    background: linear-gradient(to right, #ef48b5, #F209E1);
    top: 50%;
    right: -35%;
}

.profiles_mainSec {
    text-align: center;
}

.profile_flex {
    display: flex;
    justify-content: flex-start;
    margin-top: 25px;
    flex-wrap: wrap;
}

.profile_inner {
    width: 23%;
    margin: 1%;
    background: #fff;
    border: 1px solid #c2c2c2;
    border-radius: 10px;
    position: relative;
}

.profile_inner img {
    width: 100%;
    border-radius: 10px 10px 0px 0px;
    height: 200px;
    object-fit: cover;
}

.gender_ico_f {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
    background: #fff;
    border-radius: 50%;
    padding: 3px 11px;
    box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.5);

}

.gender_ico_m {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
    background: #fff;
    border-radius: 50%;
    padding: 3px 9px;
    box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.5);

}

.gender_ico_f i.fa-venus {
    color: #FF72A7;
    font-size: 16px;
}

.gender_ico_m i.fa-mars {
    color: #8C6DCB;
    font-size: 16px;
}

.profile_body {
    padding: 20px;
}

.profile_body h5 {
    margin: 0;
}

.profile_body h6.f-gender {
    color: #FF72A7;
    margin: 0;
}

.profile_body p.profile_location {
    margin: 0;
    margin-top: 5px;
    border-bottom: 1px dashed #c2c2c2;
    padding-bottom: 10px;
}

.profile_body p.profile_location i {
    margin-right: 4px;
    color: #ef48b5;
    font-size: 13px;
}

.profile_body p.profile_description {
    margin: 0;
    border-bottom: 1px dashed #c2c2c2;
    padding: 10px 0px;
    font-size: 14px;
}

.profile_buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
}

.profile_buttons a {
    display: block;
    margin-right: 15px;
    flex: 1 1;
}

.chat_now {
    flex: 1;
    background: linear-gradient(to right, #300243, #15021b);
    ;
    color: #fff;
    outline: none;
    border: none;
    padding: 7px;
    border-radius: 5px;
    width: 100%;
}

.add_friend {
    background: ghostwhite;
    border: 1px solid #ef48b5;
    border-radius: 50%;
    padding: 5px 9px 7px 9px;
}

.add_friend i {
    color: #ef48b5;
    font-size: 13px;
}

.load_more {
    margin-top: 35px;
}

.about_section {
    padding: 60px 15px;
    background: linear-gradient(to right, #300243, #15021b);
}

.about_flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.about_flexL {
    width: 50%;
    padding: 30px;
}

.about_flexL img {
    width: 100%;
    border-radius: 5px;
}

.about_flexR {
    width: 50%;
    padding: 30px;
}

.about_flexR h2 {
    color: #fff;
}

.about_flexR p {
    color: #fff;
}

.about_flexR button {
    margin: 0;
}

.how_it_works {
    padding: 60px 15px;
    background: linear-gradient(to right, #300243, #15021b);
    ;
}

.how_it_worksInner {
    text-align: center;
}

.how_it_worksInner img {
    width: 100%;
    margin-top: 25px;
    height: 550px;
    object-fit: cover;
}

.how_it_worksInner .main_title {
    color: #fff;
}

.how_it_worksInner .main_title::before {
    background: linear-gradient(to right, #fff, ghostwhite);
}

.how_it_worksInner .main_title::after {
    background: linear-gradient(to right, #fff, ghostwhite);
}

.footer_main {
    text-align: center;
    background: ghostwhite;
    padding: 25px;
}

.footer_main p {
    margin: 0;
    font-size: 18px;
}

.form-control:focus {
    border-color: #8C6DCB;
    box-shadow: 0 0 0 0.25rem #8a59ec77;
}

.single_lockOpen {
    padding-bottom: 25px;
    margin-bottom: 25px;
    border-bottom: 1px dashed #dfdfdf;
}

.single_lockOpen img {
    width: 100%;
    border-radius: 5px;
    margin-bottom: 15px;
    height: 35vh;
    object-fit: cover;
    object-position: top;
}
.single_lockOpen h5 {
    margin-bottom: 5px;
    text-transform: capitalize;
    font-size: 16px;
}

.single_lockOpen span.single_age {
    color: #ef48b5;
    font-weight: 600;
    margin-right: 10px;
}

.single_lockOpen span i {
    font-size: 13px;
    color: #ef48b5;
}

.single_lockOpen button.view_full {
    background: linear-gradient(to right, #300243, #15021b);
    padding: 7px 15px;
    border-radius: 5px;
    border: none;
    outline: none;
    color: #fff;
    width: 100%;
    font-size: 14px;
    margin: 15px auto;
}

.new_searchview {
    width: 100%;
}

.single_lockOpen .single_actionFlex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.single_actionFlex button {
    width: 100%;
    background: #ef48b5;
    padding: 5px 5px;
    color: #fff;
    border: none;
    outline: none;
    border-radius: 5px;
    font-size: 14px;
}

@media screen and (max-width: 767px) {
    .search_gender_inner {
        padding-left: 0px;
    }

    .range_Age {
        width: 100%;
    }

    .search_main {
        flex-direction: column;
    }

    .search_gender_inner {
        width: 100%;
        justify-content: center;
    }

    .button_search {
        width: 100%;
    }

    .search_m .ReactFlagsSelect-module_flagsSelect__2pfa2 {
        width: 60%;
    }

    .search_m {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        /* flex-direction: column; */
    }

    .active_mainArea {
        flex-direction: column;
    }

    .active_mainProfile {
        width: 100%;
    }

    .content_sec button {
        margin: auto;
    }

    .about_section {
        padding: 20px 10px;
    }

    .top_bannerInner {
        flex-direction: column-reverse;
        padding: 120px 10px 40px 10px;
    }

    .signup_sec {
        width: 100%;
        margin-top: 160px;
    }

    .heartOne {
        display: none;
    }

    .heartTwo {
        display: none;
    }

    .content_sec {
        width: 100%;
        padding: 10px 40px;
        text-align: center;
    }

    .content_sec {
        padding: 10px;
        margin-top: 150px;
    }

    .content_sec h1 {
        color: #fff;
        font-size: 30px;
        line-height: 44px;
    }

    .content_sec p {
        font-size: 15px;
    }

    .main_button {
        font-size: 14px;
        padding: 6px 10px !important;
    }

    .profile_title h2 {
        color: #333333;
        font-size: 16px;
    }

    .main_button i {
        font-size: 10px;
        position: relative;
        top: 0;
    }

    .main_buttonTwo {
        font-size: 14px;
        padding: 6px 10px;
    }

    .main_buttonTwo i {
        font-size: 10px;
        position: relative;
        top: 0;
    }

    .navbarL img {
        width: 125px;
    }

    .navbar_flex {
        padding: 15px 15px;
    }

    .credits_box {
        padding: 15px 15px !important;

    }

    .active_profilesSec {
        padding: 20px 10px;
        height: auto;
    }

    .active_secFlex {
        flex-direction: column;
        align-items: center;
    }

    .activeL {
        width: 100% !important;
        margin-bottom: 15px;
    }

    .activeM {
        width: 100% !important;
        margin-bottom: 15px;
    }

    .active_recent {
        flex-wrap: wrap;
    }

    .active_rInner a {
        width: 48%;
    }

    .active_mainFlex {
        flex-direction: column;
    }

    .active_mainL {
        width: 100%;
    }

    .active_actionSec {
        flex-wrap: wrap;
    }

    .active_actionSec button {
        width: 50%;
        margin-bottom: 10px;
        margin-right: 0px;
    }

    .active_actionSec button:first-of-type {
        margin-right: 3%;
        width: 47%;
    }

    .active_actionSec button:last-of-type {
        width: 47%;
        margin-right: 0%;
    }

    .active_actionSec button:nth-child(3) {
        width: 100%;
        margin-right: 0%;
    }

    .active_mainR {
        width: 100%;
        padding-left: 0px;
        margin-top: 15px;
    }

    .active_mainL img {
        width: 100%;
        height: 30vh;
    }

    .activeR {
        width: 100% !important;
        margin-top: 15px;
    }

    .most_visited {
        padding: 20px 10px;
    }

    .become_part {
        padding: 30px 10px;
    }

    .become_flex {
        flex-direction: column;
    }

    .becomeL {
        width: 100%;
    }

    .becomeL img {
        height: 250px;
        object-fit: cover;
        border-radius: 20px 20px 0px 0px;
    }

    .becomeR {
        width: 100%;
        padding: 40px 20px;
    }

    .profile_inner {
        width: 98%;
    }

    .main_title {
        text-align: center;
        font-size: 28px;
        position: relative;
        display: inline;
        margin: auto;
    }

    .main_title::before {
        display: none;
    }

    .main_title::after {
        display: none;
    }

    .navbarL {
        width: 30%;
    }

    .navbarR {
        width: 70%;
    }

    .about_flex {

        flex-direction: column;
    }

    .about_flexL {
        width: 100%;
        padding: 10px;
    }

    .about_flexR {
        width: 100%;
        padding: 10px;
    }
}

@media screen and (min-width: 576px) and (max-width: 992px) {
    .profile_inner {
        width: 48%;
    }

    .single_lockOpen h5 {
        word-break: break-word;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1200px) {

    .single_lockOpen h5 {
        word-break: break-word;
    }
}

@media screen and (min-width: 767px) and (max-width: 1024px) {
    .active_mainProfile {
        width: 48%;
    }

    .active_actionSec button {
        font-size: 8px;
    }

    .search_gender_inner {
        width: 50%;
        padding-left: 0px;
    }

    .range_Age {
        width: 50%;
    }

    .active_profilesSec .search_m {
        width: 50%;
    }

    .button_search {
        width: 40%;
    }

    .allFriend-column .active_mainProfile {
        width: 46% !important;
    }

    .content_sec {
        width: 60%;
        padding: 0px 40px;
        padding-left: 8%;
    }

    .signup_sec {
        width: 40%;
    }

}

@keyframes random {
    0% {
        width: 60px
    }

    50% {
        width: 75px;
    }

    100% {
        width: 60px
    }
}