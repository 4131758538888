.profile_restPassword {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-top: 70px;
    background-color: #300243;
    padding: 50px 0px;
}
.forgot_pass_main {
    border-radius: 5px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.12), 0 1px 5px 0 rgba(0,0,0,.2);
    max-width: 33%;
    margin: auto;
    padding: 40px 37px;
}
.forgot_pass_back {
    margin: 80px 0px;
    min-height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.pass_field {
    display: flex;
    flex-direction: column;
    padding-top: 14px;
    position: relative;
}
.submit-btn {
    margin: 30px 0px;
}
.submit-btn button {
    background-color: #ef48b5;
    color: #fff;
    font-size: 16px;
    border: none;
    outline: none;
    padding: 10px 20px;
    border-radius: 5px;
    width: 100%;
}
.pass_field input {
    border-bottom: 1px solid #a1a1a1;
    outline: none;
    padding: 3px 0px;
    border-top: none;
    border-right: none;
    border-left: none;
}
.pass_field label {
    font-size: 14px;
    font-weight: 300;
    color: #a1a1a1;
    margin-bottom: 6px;
}
.forgot_pass_main h2 {
    color: #4A4A4A;
    text-align: center;
    margin-bottom: 40px;
    font-size: 28px;
}
.pass_field svg {
    position: absolute;
    top: 35px;
    width: 20px;
    right: 0px;
}
@media screen and (max-width:767px) {
    .profile_restPassword {
        padding: 30px 0px;

    }
    .forgot_pass_main {
    border-radius: 5px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.12), 0 1px 5px 0 rgba(0,0,0,.2);
    max-width: 100%;
    margin: auto;
    padding: 40px 37px;
}
    
}
@media screen and (min-width:767px)and (max-width:1024px) {
    .profile_restPassword {
        padding: 44px 0px;

    }
    .forgot_pass_main {
    border-radius: 5px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.12), 0 1px 5px 0 rgba(0,0,0,.2);
    max-width: 60%;
    margin: auto;
    padding: 40px 37px;
}
.forgot_pass_back {
    min-height: 50vh;
}
    
}