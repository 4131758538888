.send_email_area {
    padding: 20px 0px;
}
.level-left {
    display: flex;
    align-items: center;
}
p.level-item {
    margin: 0px;
    font-size: 16px;
    margin-right: 5px;
}
p.free-creds {
    color: #222;
    font-size: 16px;
    border: 3px solid #ef48b5;
    padding: 3px 12px;
    border-radius: 6px;
    margin-bottom: 8px;
    background-color: #dff5e5;
}
.button_send_email button {
    background: linear-gradient(to right, #ef48b5, #F209E1);
    border-radius: 4px;
    padding: 5px 10px;
    color: #fff;
    font-size: 13px;
    margin-right: 10px;
}
.main_signUp {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.signup_popup {
    background: #fff;
    margin: auto;
    text-align: center;
    padding: 25px;
    border-radius: 20px;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.form-floating>.form-control, .form-floating>.form-control-plaintext, .form-floating>.form-select{
    height: 50px;
    min-height: 50px;
    font-size: 14px;
}
.form-floating>label{
    font-size: 14px;
    padding: 15px;
}
.form-check{
    display: inline-block;
    margin-right: 10px;
}
.form-check label{
    margin-right: 7px;
}
.form-check-input:checked{
    background-color: #ef48b5;
    border-color: #ef48b5;
}
.signup_buttons button{
    font-size: 14px;
    padding: 10px 25px;
}
.signup_topBar {
    max-width: 300px;
    display: flex;
    justify-content: space-evenly;
    margin: 20px auto 20px auto;
}
.signup_inner h2{
    font-size: 22px;
}
.signup_topBar span {
    background: #c7c7c7;
    color: #333333;
    font-size: 12px;
    padding: 5px 10px 0px 10px;
    border-radius: 50%;
    display: block;
    position: relative;
    z-index: 0;
}

.signup_topBar span.active {
    background: #ef48b5;
    color: #fff;
}

.signup_topBar span.one {
    padding: 5px 11px 3px 11px;
}
.signup_topBar span.three {
    padding: 5px 10px 3px 10px;
}

.signup_topBar span::before {
    content: '';
    position: absolute;
    /* background: linear-gradient(to right, #9D6DD3, #FF72A7); */
    background: #c7c7c7;
    width: 244% !important;
    height: 3px;
    top: 45%;
    right: 100%;
    display: none;
}

.signup_topBar span:last-of-type:before {
    content: '';
    position: absolute;
    /* background: linear-gradient(to right, #9D6DD3, #FF72A7); */
    background: #c7c7c7;
    width: 238% !important;
    height: 3px;
    top: 45%;
    right: 100%;
    display: none;
}

.signup_topBar span.active::before {
    content: '';
    position: absolute;
    background: linear-gradient(to right, #9D6DD3, #FF72A7);
    width: 225%;
    height: 3px;
    top: 45%;
    right: 100%;
    display: none;
}

.signup_topBar span:first-of-type:before {
    display: none;
}




.close_icon {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #dfdfdf;
    border: none;
    border-radius: 50%;
    padding: 7px 12px;
    color: rgb(158, 66, 49);
    font-size: 14px;
    cursor: pointer;
}

.signup_buttons {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.signup_formSec {
    text-align: left;
}

.form_field {
    margin-top: 15px;
}

.form_field p {
    margin-bottom: 5px;
}

.country button {
    background: #fff;
}

.signup_buttons button {
    margin: 0;
}

.signup_buttons button:first-of-type {
    margin-right: 25px;
}

.signup_buttons button:first-of-type i {
    margin-right: 7px;
}

.hobbies-list {
    display: flex;
    padding: 0px 3px;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.interset {
    background: linear-gradient(to right, #9D6DD3, #8C6DCB);
    color: #fff;
    font-size: 14px;
    margin-right: 10px;
    padding: 3px 12px;
    border-radius: 50px;
    margin-bottom: 10px;
}

.interset i {
    margin-left: 5px;
    cursor: pointer;
    font-size: 11px;
}
.error_bar{
    background: rgb(216, 82, 58);
    color: #fff;
    text-align: center;
    padding: 8px;
    border-radius: 5px;
    margin-bottom: 20px;
}
.error_bar p{
    margin: 0;
    font-size: 14px;
}
.forgot_pass{
    text-align: center;
    color: #333333;
    margin: 0px;
    font-weight: 600;
    position: absolute;
    left: 25px;
    bottom: 25px;
}
.op{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 30px;
    margin-bottom: 25px;
}
.opL img{
    width: 100%;
}
.opL{
    width: 20%;
}
.opR{
    text-align: left;
    padding-left: 15px;
}
.order_sec input{
    height: 22px;
    width: 22px;
}
.orders_inner{
    text-align: left;
}

.orders_inner table tbody tr .item img{
width: 20%;
}
.orders_inner table tbody tr .item{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.orders_inner table{
    border-collapse: collapse;
    border: 1px solid #dfdfdf
}
.orders_inner table thead {
    background: #02224E;
}
.orders_inner table thead th{
    padding: 5px;
    color: #fff;
}
.orders_inner table tbody td{
    padding: 5px ;
}
table,thead,tbody,tr,td,th{
    border: 1px solid #dfdfdf;
}

.orders_inner table tbody tr .item p{
   width: 80%;
   margin-left: 25px;
}
.order_button{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    max-width: 500px;
    margin: 25px auto 0px auto;
}
.order_button button:first-of-type{
    background: #fff;
    color: #02224E;
    border: 2px solid #02224E;
    padding: 8px 25px;
    border-radius: 5px;
    min-width: 150px;
}
.order_button button:last-of-type{
    background: #02224E;
    color: #fff;
    border: 2px solid #02224E;
    padding: 8px 25px;
    border-radius: 5px;
    min-width: 150px;
}

/* .order_sec .form-check{
    align-items: center;
    display: flex;
} */
@media screen and (max-width: 850px) {
    .signup_popup {
        min-width: 90%;
    }
    .forgot_pass{
        position: static;
    }
}
@media (max-width: 1024px) and (min-width: 767px){
    .signup_topBar{
      margin: 10px auto;
    }
    .level-left {
        flex-direction: column;
    }
    .button_send_email {
        padding: 20px 0px;
    }
}