.help_Desk_inner {
    max-width: 570px;
    margin: 0 auto;
    border-radius: 5px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.12), 0 1px 5px 0 rgba(0,0,0,.2);
    padding: 58px 39px;
}
.help_desk_main {
    margin: 80px 0px;
}
h2.help_main {
    text-align: center;
    font-size: 28px;
    margin-bottom: 30px;
}
.help_Desk_inner p {
    color: #000;
    font-size: 15px;
    margin-bottom: 20px;
}
.help_desk_cat select {
    width: 100%;
    border: 0;
    border-bottom: 1px solid #a1a1a1;
    padding: 10px 5px;
    margin-bottom: 20px;
    outline: none;
}
textarea.textarea {
    background-color: #fff;
    border-color: #dbdbdb;
    border-radius: 4px;
    color: #363636;
    width: 100%;
    padding: 10px 8px;
    outline: none;
}
.help_desk_textarea label {
    font-size: 16px;
    font-weight: 300;
    color: #a1a1a1;
    margin-bottom: 10px;
}
.help_desk_cat label {
    font-size: 16px;
    font-weight: 300;
    color: #a1a1a1;
    margin-bottom: 10px;
}
.help_desk_btn {
    padding-top: 20px;
}
.help-addr {
    margin-top: 3rem;
    text-align: center;
}

