@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@400;600;700&family=Outfit:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;600&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Outfit', sans-serif;
  top: 0px !important;
}

button,
span,
a {
  font-family: 'Outfit', sans-serif;
}
a{
  text-decoration: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Outfit', sans-serif;
  font-weight: 600 !important;
}

div {
  font-family: 'Outfit', sans-serif;
}

.profile_bannerSec {
  /* background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../src/images/profile_b.jpg); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  /* min-height: 45vh; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: 70px;
  background-color: #300243;
}

.profile_bannerSec .container {
  text-align: center;
}

.profile_bannerSec h1 {
  color: #fff;
  font-size: 72px;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  line-height: 75px;
  margin-bottom: 15px;
}
.profile_bannerSec h2 {
  color: #fff;
}

.profile_bannerSec span {
  font-size: 20px;
  color: #fff;
}

.main_pageSec {
  padding: 40px 10px;
  background: url(../src/images/abstractTwo.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.skiptranslate iframe {
  display: none !important;
  visibility: hidden !important;
  height: 0px !important;
}

#google_translate_element .goog-te-gadget {
  font-family: 'Rubik', sans-serif !important;
  font-size: 0px !important;
}

#google_translate_element .goog-te-gadget span {
  display: none !important;
}

#google_translate_element .goog-te-gadget .goog-te-combo {
  font-family: 'Rubik', sans-serif !important;
  border: 1px solid transparent;
  padding: 7px 3px;
  border-radius: 5px;
  font-weight: 600;
  outline: none !important;
}
.translater_main {
  margin-right: 24px;
}
@media screen and (max-width: 767px) {
  .profile_bannerSec h1 {
    color: #fff;
    font-size: 48px;
    line-height: 52px;
  }
}