.promo_item {
    display: flex;
    align-items: center;
    position: relative;
}
.yellow_area {
    background: #ef48b5;
    padding: 20px 0px;
}
.yellow_area h2 {
    font-size: 25px;
    color: #Fff;
}
.yellow_area i { 
  margin-right: 20px;
  cursor: pointer;
}
.packages_box_area {
    min-height: 50vh;
}
.tow_packages {
    background: #F8F7F5;
    padding: 20px 0px;
}
.tow_packages p {
    margin: 0px;
    font-size: 16px;
}
.tow_packages i {
    margin-right: 20px;
    font-size: 20px;
}
.step_packages {
    background: #E5E5E5;
    padding: 20px 0px;
}
.step_package_inner {
    display: flex;
    align-items: center;
}
.step_package_inner h2 {
    width: 8%;
    margin: 0px;
    font-size: 24px;
    font-weight: 400;
}
.step_package_inner h3 {
    margin: 0px;
    font-size: 24px;
    font-weight: 400;

}
.packages_boxes_area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #E5E5E5;
    margin: 20px 0px;
    border-radius: 15px;
}
.packages_first {
    background: #ef48b5;
    padding: 10px 20px;
    border-radius: 10px 0px 0px 10px;
    width: 20%;
}
.packages_first h2 {
    font-size: 20px;
    margin: 0px;
    text-align: center;
    color: #fff;
}
img.plus_img {
    position: absolute;
    width: 8% !important;
    left: -12px !important;
    top: 19px !important;
}
.packages_last {
    background: #180220;
    padding: 18px 35px;
    border-radius: 0px 10px 10px 0px;
    width: 15%;
    text-align: center;
}

.packages_last button {
    font-size: 20px;
    margin: 0px;
    text-align: center;
    color: #fff;
}
.packages_gift {
    width: 20%;
    background: #67b45f;
    padding: 10px 0px;
}
.promo_item h3 {
    font-size: 20px;
    margin: 0px;
    text-align: center;
    color: #fff;
    position: relative;
}
.promo_item img {
    width: 12%;
    margin: 0px 5px;
}
.promo_item h2 {
    font-size: 18px;
    margin: 0px 10px;
    color: #fff;
}
.packages_middle {
    width: 45%;
    text-align: center;
}
.packages_middle h3 {
    margin: 0px;
    font-size: 28px;
}
.packages_gift_two{
    width: 20%;
}
@media screen and (max-width: 767px) {
    .step_package_inner h2 {
        width: 18%;        
        font-size: 16px;
    }
    .step_package_inner h3 {
        font-size: 16px;
    }
    .packages_middle h3 {
        margin: 0px;
        font-size: 20px;
    }
    .yellow_area h2 {
        font-size: 20px;
        color: #Fff;
    }
    img.plus_img {
        position: absolute;
        width: 28% !important;
        left: -15px !important;
        top: 19px !important;
    }
    .promo_item {
        flex-direction: column;
    }
    .packages_first {
        padding: 25px 20px;
        width: 25%;

    }
    .packages_gift {
        width: 25%;
    }
    .packages_middle {
        width: 35%;
    }
    .promo_item h2 {
        font-size: 14px;
    }
    .promo_item img {
        width: 15%;
    }
    .packages_first h2 {
        font-size: 14px;
    }
    .promo_item h3 {
        font-size: 14px;
    }
    .packages_last button {
        font-size: 14px;
    }
    .packages_last {
        text-align: center;
        display: flex;
        justify-content: center;
        padding: 30px 35px;

    }
    .promo_item {
        justify-content: center;
    }
    .packages_middle h3 {
        font-size: 16px;
    }

}

@media screen and (min-width: 767px)and (max-width:1024px) {
    .packages_gift {
        width: 28%;
    }
    .step_package_inner h2 {
        width: 15%;
    }
    img.plus_img {
        left: -15px !important;
    }
}
